import axios from "axios";
import toast from "react-hot-toast";
export const URL = "http://localhost:8080/api";
export const URLApi = "https://atlantisrb.com/api/admin";
// export const URLApi = "http://192.168.1.150:8000/api/admin";

// export const URLApi = "https://corecrowd.io/admin";

export function cutAfterDecimal(number, pos, dl, ac) {
  if (Number(number)) {
    if (dl) {
      const limit = dl?.decimalLimit[ac] > 0 ? dl?.decimalLimit[ac] : 5;
      const res =
        number?.toString()?.indexOf(".") > -1
          ? number
              .toString()
              .slice(0, number.toString().indexOf(".") + limit + 1)
          : number;
      return res;
    } else {
      const res =
        number?.toString()?.indexOf(".") > -1
          ? number.toString().slice(0, number.toString().indexOf(".") + pos + 1)
          : number;
      return res;
    }
  } else {
    return 0;
  }
}

const formatDateTime = (dateString) => {
  const date = new Date(dateString); // Create a Date object from the dateString
  const timestampInMilliseconds = date.getTime();
  return timestampInMilliseconds; // Return the timestamp
};

export async function adminLogin(email, password) {
  try {
    const requestBody = {
      email: email,
      password: password,
    };

    const response = await axios.post(`${URLApi}/login`, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(response);
    console.log(response.data, "response.data");
    const token = response.data.token;

    if (token) {
      localStorage.setItem("adminToken", token); // Store token with the key 'adminToken'
      console.log("Token stored successfully in localStorage");
    }
    return response.data;
  } catch (error) {
    console.log("Error login Admin:", error);
  }
}

export function replyTicket(
  ticketId,
  replymessage,
  replyfile,
  closed,
  subject,
  token
) {
  const formData = new FormData();
  if (replyfile) {
    formData.append("reply", replyfile, replyfile.name);
  }
  // formData.append("mobile", mobile);
  // formData.append("tokenId", sessionId);
  formData.append("closed", closed);
  formData.append("message", replymessage);
  formData.append("subject", subject);
  formData.append("ticketId", ticketId);

  return axios
    .post(URL + "/reply-tickets", formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res)
    .catch((e) => {
      console.log(e);
    });
}

export const getAllChatsList = async (token) => {
  try {
    const res = await axios.get(`${URLApi}/support-chats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export function raisedTicketList(address, token) {
  return fetch(URL + "/tickets-list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "allow-access-control-origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      address: address,
      page: 1,
      limit: 10,
    }),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e, "Error in raisedTicketList()::apis.tsx");
    });
}

export async function daoUsersAdd(address, token) {
  try {
    const requestBody = {
      address: address,
    };

    const response = await axios.post(`${URLApi}/addDAOUser`, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error login Admin:", error);
  }
}

export async function getDAOUserList(walletAddress, token) {
  try {
    const response = await axios.get(`${URLApi}/getDAOUserList`, {
      params: {
        address: walletAddress,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error getDAOUserList Admin:", error);
  }
}

export async function getAllStakeUsers(page, limit, filter, token) {
  try {
    const response = await axios.get(`${URLApi}/getAllStakeUsers`, {
      params: {
        page: page,
        limit: limit,
        address: filter,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error getDAOUserList Admin:", error);
  }
}

export async function getStakeSummary(token) {
  try {
    const response = await axios.get(`${URLApi}/getStakeSummary`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error getDAOUserList Admin:", error);
  }
}

export async function updateStakeSetting(newSettings, token) {
  try {
    const response = await axios.post(
      `${URLApi}/updateStakeSetting`,
      newSettings,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error login Admin:", error);
  }
}

export async function getStakeSetting(token) {
  try {
    const response = await axios.get(`${URLApi}/getStakeSetting`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error getDAOUserList Admin:", error);
  }
}

export async function getTop3IdData(token) {
  try {
    const response = await axios.get(`${URLApi}/getTop3IdData`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error getDAOUserList Admin:", error);
  }
}

export async function getUserNodeGroupData(page, limit, filter, type, token) {
  try {
    const response = await axios.get(`${URLApi}/getUserNodeGroupData`, {
      params: {
        page: page,
        limit: limit,
        address: filter,
        type: type,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error getDAOUserList Admin:", error);
  }
}

export async function nodeGroupApproveAction(selectedIds, token) {
  try {
    const requestBody = {
      selectedIds: selectedIds,
    };
    const response = await axios.post(
      `${URLApi}/nodeGroupApproveAction`,
      JSON.stringify(requestBody),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data, "response.data");
    return response.data;
  } catch (error) {
    console.log("Error nodeGroupApproveAction:", error);
  }
}
