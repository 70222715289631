import React, { Fragment, useEffect, useState, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Table, Form, Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import axios from "axios";
import Papa from "papaparse";
import {
  cutAfterDecimal,
  getAllStakeUsers,
  URLApi,
} from "../../../services/api_function";
import toast from "react-hot-toast";
import { Tooltip, IconButton } from "@mui/material";
import { FaRegCopy } from "react-icons/fa";
import moment from "moment";
import {
  getIsUserExist,
  getOperator,
  stakeUsdtByAdmin,
} from "./web3/transfert";
import { useAccount } from "wagmi";

export const UserDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [RoiWithdrawList, setRoiWithdrawList] = useState([]);
  const [tooltipText, setTooltipText] = useState("Copy address");
  const [filteredData, setFilteredData] = useState([]);
  const [reload, setReload] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [depositDetails, setDepositDetails] = useState([]);
  const [withdrawDetails, setWithdrawDetails] = useState([]);
  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    setTooltipText("Copied!");
    setTimeout(() => setTooltipText("Copy address"), 2000);
  };

  const token = localStorage.getItem("adminToken");
  const fetchData = async () => {
    //     try {
    //       const response = await axios.post(
    //         `${URLApi}/admin/user-all-data`,
    // {
    //   user:
    // }
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       );
    //       let alluserDetails = response.data.data.licensePurchase;
    //       console.log(alluserDetails, "res");
    //       // setTotalPages(response.data.meta.totalPages);
    //       setUserDetails(alluserDetails);
    //       // setFilteredData(allRoiWithdrawList);
    //     } catch (error) {
    //       console.log(error);
    //     }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, reload]);
  // const handleSearch = (e) => {
  //   const query = e.target.value.toLowerCase().trim();
  //   setSearch(query);
  //   const filtered = RoiWithdrawList.filter((item) =>
  //     item?.user?.toLowerCase().includes(query)
  //   );
  //   setFilteredData(filtered);
  // };
  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const [userAddress, setUserAddress] = useState("");
  const handleUserAddressChange = async (e) => {
    const value = e.target.value;
    setUserAddress(value);
    console.log("user address", userAddress);
  };
  // const handleSubmit = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${URLApi}/admin/user-all-data`,
  //       {
  //         user: userAddress,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     // setTotalPages(response.data.meta.totalPages);
  //     const value = e.target.value;
  //     setUserAddress(value);

  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      // Make the API call
      const response = await axios.post(
        `${URLApi}/admin/user-all-data`,
        {
          user: userAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API Response:", response.data);

      setUserAddress(userAddress);
      let userlist = response.data.data.licensePurchase;
      console.log(userlist, "userlist");
      setUserDetails(userlist);

      let depositlist = response.data.data.stakeDetail;
      console.log(depositlist, "depositlist");
      setDepositDetails(depositlist);

      let withdrawlist = response.data.data.approveWithdrawal;
      console.log(withdrawlist, "withdrawlist");
      setWithdrawDetails(withdrawlist);
      // setTotalPages(response.data.meta.totalPages);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <Fragment>
      <Row className="mb-4">
        <Col lg={6} className="mx-auto">
          <Card>
            <Card.Body>
              <Form>
                {/* Radiant Input and Submit */}
                <Form.Group controlId="formRadiantInput">
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="text"
                      placeholder="User"
                      value={userAddress}
                      onChange={handleUserAddressChange}
                      className="me-2"
                    />
                    <Button variant="primary" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>User Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Address</th>
                    <th>Refferal Id </th>
                    <th>Claimed ROI</th>
                    <th>Total Direct Member</th>
                    <th>Total Refferal Reward</th>

                    <th>Team Profit</th>
                  </tr>
                </thead>
                <tbody>
                  {userDetails?.length > 0 ? (
                    userDetails?.map((userdetail, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * 20 + index + 1}</td>
                        <td>
                          {userdetail?.user?.slice(0, 5)}...
                          {userdetail?.user?.slice(-4)}
                          <Tooltip title="Copy User Address" arrow>
                            <IconButton
                              onClick={() => handleCopy(userdetail?.user)}
                              size="small"
                              style={{ marginLeft: 4 }}
                            >
                              <FaRegCopy />
                            </IconButton>
                          </Tooltip>
                        </td>

                        <td>
                          {userdetail?.referal?.slice(0, 5)}...
                          {userdetail?.referal?.slice(-4)}
                        </td>
                        <td>{userdetail?.claimedRoi?.toFixed(2)}</td>

                        <td>{userdetail?.totalDirect}</td>
                        <td>
                          {userdetail?.directIncome + userdetail?.TotalLevel}
                        </td>

                        <td>{userdetail?.teamProfit?.toFixed(2)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Records Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Deposit Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Address</th>
                    <th>Amount</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {depositDetails?.length > 0 ? (
                    depositDetails?.map((deposit, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * 20 + index + 1}</td>
                        <td>
                          {deposit?.user?.slice(0, 5)}...
                          {deposit?.user?.slice(-4)}
                          <Tooltip title="Copy User Address" arrow>
                            <IconButton
                              onClick={() => handleCopy(deposit?.user)}
                              size="small"
                              style={{ marginLeft: 4 }}
                            >
                              <FaRegCopy />
                            </IconButton>
                          </Tooltip>
                        </td>
                        <td>{(Number(deposit?.amount) / 1e18)?.toFixed(2)}</td>

                        <td>
                          {moment
                            .unix(deposit?.startTime)
                            .format("DD-MM-YY hh:mm:ss A")}
                        </td>
                        <td>
                          {moment
                            .unix(deposit?.endTime)
                            .format("DD-MM-YY hh:mm:ss A")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Records Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Withdrawal Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Address</th>
                    <th>Amount</th>
                    <th>Withdrawal Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawDetails?.length > 0 ? (
                    withdrawDetails?.map((withdraw, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * 20 + index + 1}</td>
                        <td>
                          {withdraw?.user?.slice(0, 5)}...
                          {withdraw?.user?.slice(-4)}
                          <Tooltip title="Copy User Address" arrow>
                            <IconButton
                              onClick={() => handleCopy(withdraw?.user)}
                              size="small"
                              style={{ marginLeft: 4 }}
                            >
                              <FaRegCopy />
                            </IconButton>
                          </Tooltip>
                        </td>
                        <td>{Number(withdraw?.totalAmount)?.toFixed(2)}</td>

                        <td>{withdraw?.claimType}</td>
                        {withdraw?.txnStatus === 1 && <td>Success</td>}
                        {withdraw?.txnStatus === 0 && <td>Pending</td>}
                        {withdraw?.txnStatus === -1 && <td>Failed</td>}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Records Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UserDetails;
